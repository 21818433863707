import React, { useState , useEffect } from 'react';
import { Link  ,useHistory } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalMallIcon from '@material-ui/icons/LocalMall';



import pay from '../assets/images/pay.png';




import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';



import Swal from 'sweetalert2'





const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    // categoriesName subcategories
    let history = useHistory();

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")
        if(JSON.parse(localStorage.getItem("All1"))){
        let ab1 = JSON.parse(localStorage.getItem("All1"))
        setallproduct(ab1)
        const NonActive = ab1.filter((res9,i)=>{
            // console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
            if(res9.status && res9.Product_Catagories === "Cars"  ){
                return res9 
            }
        })

        setSubproduct(NonActive)
        console.log(NonActive)
      }
      else{
        fetch("https://falcon-rides-main-back.vercel.app/AllProduct",{
                  method: "GET",
                  headers :  {
                  "Content-Type" : "application/json" , 
              } ,
              })
              .then(res7=>res7.json())
              .then(res8=>{
                  setallproduct(res8)
                  const NonActive = res8.filter((res9,i)=>{
                      // console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                      if(res9.status && res9.Product_Catagories === "Cars"  ){
                          return res9 
                      }
                  })

                  setSubproduct(NonActive)
                  console.log(NonActive)
              })
      }

        
        

        

      



},[])


    const setSortPrice1 = (e)=>{
      setdispaluy53(!dispaluy53)
      if(Subproduct.length !== 0){

        SortedName.push(e)
        console.log(e,Subproduct);
        if (e === "Low"){
            const Pro6 = Subproduct.sort(function (a, b) {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                  });
                  console.log(Pro6);
                  let reversed_array = [];
                  Pro6.forEach((element) => {
                    reversed_array.unshift(element);
                });
                  console.log(Pro6,reversed_array)
                  setSubproduct(reversed_array)
                  setSubproduct(Pro6)
                  setdispaluy53(!dispaluy53)
                  setdispaluy53(!dispaluy53)
    
        }
        if (e === "High"){
            const Pro6 = Subproduct.sort((a, b)=> {
                    return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                    
                });
              //   let reversed_array = [];
              //   Pro6.forEach((element) => {
              //     reversed_array.unshift(element);
              // });
              //   console.log(Pro6,reversed_array)
                setSubproduct(Pro6)
                setdispaluy53(!dispaluy53)
                setdispaluy53(!dispaluy53)

        }
        if (e === "A"){
            const Pro6 = Subproduct.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
    
                return 0;
                });
                console.log(Pro6);
                setSubproduct(Pro6)
                
              }
              
              setSubproduct1([])
              setdispaluy53(!dispaluy53)

            }
            else{
        
                SortedName.push(e)
                console.log(e);
                if (e === "Low"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6)
            
                }
                if (e === "High"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6.reverse())
            
                }
                if (e === "A"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                        var nameA = a.Product_Name.toUpperCase(); 
                        var nameB = b.Product_Name.toUpperCase(); 
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
            
                        return 0;
                        });
                        console.log(Pro6);
                        setSubproduct1(Pro6)
                        
                      }
                      
                      
                      setSubproduct([])
                    }
                    setdispaluy53(!dispaluy53)

                  }
  

  
const addtocartproduct = (productDetail) =>{
  Swal.fire({
    title: 'Your Rent a Car in Cart!',
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: 'Coutinue Rent a Car',
    denyButtonText: `View Cart`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      // Swal.fire('Saved!', '', 'success')
    } else if (result.isDenied) {
      props.history.push("/cart")
      // Swal.fire('Changes are not saved', '', 'info')
    }
  })
      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}


const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
        return (
       




<>
          <main className="main">
          <div className="text-center">
            <div className="container">
              <h2 className="page-title" style={{fontSize:"27px",fontWeight:"bold", margin:"0px 10px",padding:0,textTransform:"uppercase"}}>Rent a Car Jumeirah Beach  <span></span></h2>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
<hr  style={{margin:"0px"}}/>
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="toolbox">
                    <div className="toolbox-left" style={{width:"100%"}}>
                      <div className="toolbox-info" style={{width:"100%"}}>
                     <div className='dskjffkj' style={{width:"100%",display:"flex",color : "black"}}>
                       
                        <div>
                          <span style={{color : "black",fontSize:"18px"}}>{Subproduct.length} <b>Cars</b>  founds</span>
                        </div>
                        </div> 
                        
                      </div>{/* End .toolbox-info */}
                    </div>{/* End .toolbox-left */}
                    
                  </div>{/* End .toolbox */}
                  <br/>

                  <div className="products mb-3">
                    <div className="row justify-content-center">


                    {Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto"}}>
                                <figure className="product-media">
                                  <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  {/* <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div> */}
                                  {/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body" style={{height:"400px"}}>
                                  <div style={{display:"flex",marginBottom:"15px"}}>
                                 <div style={{height:"47px",width:"4px",background:"grey",marginRight:"5px"}}>

                                 </div>
                                  <h3 className="product-title"   style={{fontSize:"18px",marginTop:"15px",maxWidth:"70%",maxHeight : "59px",overflow : "hidden",fontWeight:"bold",textAlign:"left"}}><Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
                                    
                                  <a href="tel:+971563619373">  <AddIcCallIcon  style={{fontSize:"35px",marginTop:"15px",position:"absolute",right:"10%",padding :"5px 5px" ,color:"#96040e",borderRadius:"50px",background:"white",boxShadow:"2px 1px grey",}}/></a>
                                  <a href={`https://wa.me/971563619373?text=Hi *Falcon Rides*! I need more info Detail about this ${res.Product_Name}`} target="_blank"> <WhatsAppIcon  style={{fontSize:"35px",marginTop:"15px",position:"absolute",right:"23%",padding :"5px 5px" ,color:"#57bb63",background:"white",boxShadow:"2px 1px grey",borderRadius:"50px"}}/></a>
                                    
                                     </div>
                                 {/* <br /> */}
                                  {/* <span style={{display:"flex"}}>
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                        <IconStar width={15} height={15} />
                      </span> */}
                                  {/* <hr style={{margin:"0px"}}/>          
                                  { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#96040e",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",fontWeight:"bold",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         <img src={pay} alt="" style={{marginLeft:"10px",height:20}}/>
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>   */}
                      <div style={{display:"flex",width:"95%",margin:"0px auto 0px auto"}}>
                        
                        <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"center",marginTop:"10px",border:"1px solid grey",padding:"10px 5px",width:"33.33%",cursor :"pointer"}} onClick={()=>{props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}}>
                                <span>
                                <span style={{color  :"black", fontWeight:"400"}}>Per Day</span> 
                                <br />
                                 {res.Product_Price_Discounted ? 
                 <><span style={{fontSize:"14px",fontWeight:"500",color:"red",textDecoration: "line-through"}}>AED {res.Product_Price} </span><br /> <span style={{fontSize:"14px",fontWeight:"500",color:"green"}}>AED {res.Product_Price_Discounted}</span> </>
                 :
                 <span  style={{fontSize:"14px",fontWeight:"500",color:"green"}}>
                AED {(res.Product_Price)} <br />
            / Day
                 </span>            
                                   } 
                                </span>
                               </div> 
                       
                        <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"center",marginTop:"10px",border:"1px solid grey",padding:"10px 5px",width:"33.33%",cursor :"pointer"}} onClick={()=>{props.history.push("/categories-weekly/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}}>
                                <span>
                                <span style={{color  :"black", fontWeight:"400"}}>Per Week</span> 
                                <br />
                                 {res.w1 ? 
                 <><span style={{fontSize:"14px",fontWeight:"500",color:"red",textDecoration: "line-through"}}>AED {res.w1} </span><br /> <span style={{fontSize:"14px",fontWeight:"500",color:"green"}}>AED {res.w2}</span> </>
                 :
                 <span  style={{fontSize:"14px",fontWeight:"500",color:"green"}}>
                AED {(res.w2)} <br />
            / Day
                 </span>            
                                   } 
                                </span>
                               </div> 
                       
                        <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"center",marginTop:"10px",border:"1px solid grey",padding:"10px 5px",width:"33.33%",cursor :"pointer"}} onClick={()=>{props.history.push("/categories-montly/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}}>
                                <span>
                                <span style={{color  :"black", fontWeight:"400"}}>Per Month</span> 
                                <br />
                                 {res.m1 ? 
                 <><span style={{fontSize:"14px",fontWeight:"500",color:"red",textDecoration: "line-through"}}>AED {res.m1} </span><br /> <span style={{fontSize:"14px",fontWeight:"500",color:"green"}}>AED {res.m2}</span> </>
                 :
                 <span  style={{fontSize:"14px",fontWeight:"500",color:"green"}}>
                AED {(res.m2)} <br />
            / Day
                 </span>            
                                   } 
                                </span>
                               </div> 
                       
                       
                       </div>  



                      <div style={{display:"flex",width:"95%",margin:"0px auto 10px auto"}}>
                        
                        <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"center",marginTop:"0px",padding:"0px 30px",width:"100%",cursor :"pointer",flexDirection:"column",alignItems:"flex-start"}} onClick={()=>{props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}}>
                          
                                <span style={{color  :"black",fontSize:"15px", fontWeight:"500"}}>✔ Minimum Documents Required
</span> 
                                <span style={{color  :"black",fontSize:"15px", fontWeight:"500"}}>
                                ✔ Drop off Anywhere in Dubai
</span> 
                                <span style={{color  :"black",fontSize:"15px", fontWeight:"500"}}>
                                ✔ Service & Maintenance Free
</span> 
                                <span style={{color  :"black",fontSize:"15px", fontWeight:"500"}}>
                                ✔ 24/7 Roadside Assistance
</span> 
                            
                               
                   
                               </div> 
                       
                     
                       
                       
                       </div>   



                                    {/* <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"#96040e",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span> */}
                                    <div className="shadow-none" style={{borderRadius:"5px",width:"80%",color:"white",background :"#96040e",padding :"7px 45px",margin:"5px auto",fontSize:"19px",fontWeight:"600",cursor:"pointer"}} onClick={()=>{props.history.push("/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}}>VIEW DETAILS</div>
                                    {/* <div className="shadow-none" style={{borderRadius:"5px",width:"80%",color:"white",background :"#96040e",padding :"7px 45px",margin:"5px auto",fontSize:"20px",fontWeight:"bold",cursor:"pointer"}} onClick={()=>addtocartproduct([res])}>VIEW DETAILS</div> */}
                                </div>
                              </div>
                            </div>
       )
    })
}  

      
      
<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
             
<section>

<h2>Rent a Car Jumeirah Beach Dubai</h2>
<p style={{color  :"black",fontWeight:"400"}}>
Falcon Rides Car Rental Dubai is a professional <b>car rental company in Dubai</b> . We are committed to offering our customers the best-in-class service at affordable rates. When you are looking to  <b>rent a car in Dubai</b>, you can choose from our exclusive range of over 50 cars from top brands like Hyundai, Kia, Mitsubishi, Toyota, and more. Whether you want an economy car, SUV, or sedan, you will find your perfect ride, only at Falcon Rides Car Rental Dubai.
<br/>
<br/>
Falcon Rides Car Rental Dubai makes it easy for you to book your next <b>Dubai car rental</b>. We work with the <b>best car rental companies in Dubai</b>, bringing you discount car rental rates and a wide variety of car rental classes including economy, compact, midsize, full-size, luxury, full size and SUV rentals. Our <b>car rental companies in Dubai</b> offer different pick-up and drop-off options. If you are ready to book your Dubai car rental, follow the links on this page to view more information and details on your next short or long term car rental.
<br/>
<br/>
<h2>Best Rent a Car in Jumeirah Beach Dubai</h2>

Are you in search of an <b>affordable rent a car provider in Dubai</b> ? If so, then welcome to <a href="https://www.falcon-rides.com/carrental-dubai" style={{color:"black"}}><b>Car Rentals Dubai</b></a>, the most reliable and trusted car hire service in the UAE. Browse through our exclusive rental inventory and choose from a wide range of luxury cars, economy cars, and SUVs. We take pride in ourselves for providing the <b>cheapest car rentals</b>  deals to both residents and international visitors who seek the country’s luxury lifestyle and endless sunshine. Although we act as a cheap rental solution to our customers, we do not cut back on the quality of our service.
<br/>
<br/>
Falcon Rides Car Rental Dubai offers a wide selection of <b>rental cars in Dubai</b> to meet nearly every need.
<br/>
<br/>
<h2>affordable Rent a Car Jumeirah Beach Dubai</h2>

Find the best place to get your Dubai rental cars by searching with Falcon Rides Car Rental Dubai
<br/>
<br/>
To book a car with Falcon Rides Car Rental Dubai, browse through our website and book online. You can also give us a call or WhatsApp us on +971 56 361 9373
  </p>    </section>

    </div>
            </div>
            </div>
         

                      
                    </div>{/* End .row */}
                  </div>{/* End .products */}
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev" href="#" aria-label="Previous" tabIndex={-1} aria-disabled="true">
                          <span aria-hidden="true"><i className="icon-long-arrow-left" /></span>Prev
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
                      {/* <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item-total">of 6</li> */}
                      <li className="page-item">
                        <a className="page-link page-link-next" href="#" aria-label="Next">
                          Next <span aria-hidden="true"><i className="icon-long-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>{/* End .col-lg-9 */}
                
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}




</>
        )
    }



export default SubCategoriesProduct;